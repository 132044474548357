<template>
  <div class="word-list">
    <div class="word-list-content" :class="{ hasList: list && list.length }">
      <div class="word-list-header">
        <p class="name">{{ i18n.Home.word }}</p>
        <p class="phonetic">{{ i18n.Home.Phonetic }}</p>
        <p class="explains">{{ i18n.Home.interpretation }}</p>
      </div>
      <ul v-loading="loading">
        <template v-if="list && list.length">
          <li v-for="(item, index) of list" :key="item.id">
            <div class="name">
              <span class="words-name">{{ item.words }}</span>
              <span>
                <i
                  class="f-icon icon-trumpet play-btn"
                  @click.stop="clickVolume(index)"
                />
                <audio :src="item.url" ref="audio" />
              </span>
            </div>
            <div class="phonetic">{{ item.aore }}:/{{ item.phonetic }}/</div>
            <div class="explains">
              <div
                v-for="(explains, explainsIndex) in item.explains"
                :key="explainsIndex"
                class="explains"
              >
                {{ explains }}
              </div>
            </div>
            <i
              class="f-icon icon-del del-btn"
              @click.stop="delWords(item.words)"
            />
          </li>
        </template>
      </ul>
      <Empty v-if="!loading && (!list || !list.length)" />
    </div>
    <el-pagination
      v-if="list && list.length"
      small
      layout="prev, pager, next"
      class="word-list-pagination"
      @current-change="fetchList"
      :page-count="pagination.pageCount"
      :page-size="pagination.pageSize"
      :current-page.sync="pagination.currentPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiMyNewWordList, apiMyNewWordDel } from '@/api/api.js'
import Empty from '@/components/Empty'
import i18n from '@/i18n/i18n'

export default {
  components: {
    Empty,
  },
  data() {
    return {
      list: [],
      pagination: {
        pageCount: 0,
        currentPage: 1,
        pageSize: 10,
      },
      loading: false,
    }
  },
  created() {
    this.fetchList()
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    fetchList(page) {
      if (this.loading) {
        return
      }
      this.loading = true
      if (page) {
        this.pagination.currentPage = page
      }
      apiMyNewWordList({
        page: this.pagination.currentPage,
        page_size: this.pagination.pageSize,
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.list = res.data.list
          this.pagination.pageCount = res.data.count_page
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickVolume(index) {
      this.$refs.audio[index].play()
    },
    delWords(words) {
      this.$confirm(
        `<div><div style="position: absolute; top: -26px; color: #A4AFB7;">${
          i18n.locale === 'ch' ? '删除单词' : 'Delete word'
        } </div><p style="color: #1F1F40; padding-top: 10px; border-top: 1px solid #EBEBF2;">${
          i18n.locale === 'ch'
            ? 'Are you sure you want to delete this word?'
            : '确定要删除这个单词吗？'
        } </p></div>`,
        '',
        {
          customClass: 'del-words-dialog',
          dangerouslyUseHTMLString: true,
          confirmButtonText: i18n.locale === 'ch' ? '确定' : 'Determine',
          cancelButtonText: i18n.locale === 'ch' ? '取消' : 'Cancel',
          callback: (action) => {
            this.showPayDialog = false
            if (action === 'confirm') {
              apiMyNewWordDel({
                words,
              }).then((res) => {
                if (res.code != 200) {
                  this.$layer.msg(res.msg)
                  return
                }
                this.$layer.msg(
                  i18n.locale === 'ch' ? '删除成功' : 'Delete succeeded'
                )
                this.fetchList(1)
              })
            }
          },
        }
      )
    },
  },
}
</script>

<style lang="scss">
.word-list {
  margin: 0 auto;
  width: 1014px;
  .name {
    width: 250px;
    flex-shrink: 0;
  }
  .phonetic {
    width: 200px;
    flex-shrink: 0;
  }
  .explains {
    width: 460px;
    flex-shrink: 0;
  }
  .words-name {
    display: inline-block;
    width: 160px;
  }
  .word-list-content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    &.hasList {
      min-height: 780px;
    }
    ul {
      padding: 0 40px;
      li {
        display: flex;
        padding: 20px 0;
        color: #1f1f40;
        font-size: 14px;
        border-bottom: 1px solid #ebebf2;
        &:last-child {
          border: none;
        }
        .play-btn {
          font-size: 14px;
          color: #3b86ff;
          cursor: pointer;
        }
        .del-btn {
          font-size: 14px;
          color: #d5d5d5;
          cursor: pointer;
          margin-left: auto;
          &:hover {
            color: #3b86ff;
          }
        }
      }
    }
  }
  .word-list-header {
    display: flex;
    padding: 0 40px;
    height: 90px;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .word-list-pagination {
    text-align: center;
    margin-top: 15px;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}

.del-words-dialog {
  .el-button--primary {
    background: #3b86ff;
    border-color: #3b86ff;
    border-radius: 5px;
    padding: 4px 18px;
  }
  .el-message-box__header {
    padding-top: 30px;
  }
  .el-message-box__content {
    padding: 0 15px;
  }
  .el-button:not(.el-button--primary) {
    color: #7c8084;
    padding: 4px 18px;
    border-radius: 5px;
    .f-icon {
      color: #7c8084;
    }
    &.active,
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;
      .f-icon {
        color: #3b86ff;
      }
    }
    &:focus {
      &.active,
      &:hover {
        color: #3b86ff;
        background: #ecf3ff;
        border-color: #3b86ff;
        .f-icon {
          color: #3b86ff;
        }
      }
      color: #7c8084;
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
